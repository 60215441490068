import { ChatmessageRouteNames } from '@/app/router/routes'
import { useSalespointModel } from '@/entities/salespoint'
import { useUserModel } from '@/entities/user'
import { lazyRef } from '@/shared'
import { useWebsocketStore } from '@/store'
import { defineStore, storeToRefs } from 'pinia'
import { useToast } from 'primevue/usetoast'
import {
  Chatmessage,
  ChatmessageThread,
  MessageUpdateWithGroupId,
  MessageUpdateWithSalespointIds,
  useDeleteChatmessage,
  useGetChatmessageList,
  useGetChatmessageThreads,
  useReadChatmessage,
  useUpdateChatmessage
} from 'simpleloan_api'
import { computed, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { getShortMessage, getUserName } from '../lib'

// function debounce(func: () => any, wait: number) {
//   let timeout: number
//   return () => {
//     clearTimeout(timeout)
//     timeout = setTimeout(() => func.apply(this), wait)
//   }
// }

export const useModel = defineStore('chatmessage-model', () => {
  const toast = useToast()
  const router = useRouter()
  const { user } = storeToRefs(useUserModel())
  const { salespoint } = storeToRefs(useSalespointModel())

  const websocketStore = useWebsocketStore()

  /**
   * Апи метод получения списка тредов
   */
  const _getThreads = useGetChatmessageThreads()

  /**
   * Список тредов
   */
  const threads = ref<ChatmessageThread[] | null>(null)

  /**
   * Id текущего выбранного треда
   */
  const threadId = ref<number | null>(null)

  /**
   * Текущий выбранный тред
   */
  const thread = computed(() => threads.value?.find(thread => thread.id === threadId.value))

  /**
   * Список сообщений
   */
  const messages = ref<Chatmessage[] | null>(null)

  /**
   * Размер одной страницы сообщений
   */
  const messagesPageSize = 25

  /**
   * Общие кол-во сообщений в thread
   */
  const messagesTotalSize = ref<number | null>(null)

  /**
   * Номер страницы в сообщениях
   */
  const messagesPageNumber = computed(() => (messages.value ? Math.ceil(messages.value.length / messagesPageSize) : 0))

  /**
   * Индекс с какого элемента сделать следующий запрос
   */
  const messagesStartIndex = computed(() => messagesPageNumber.value * messagesPageSize)

  /**
   * Индикатор того, что юзер из поддержки
   */
  const isChatSupport = computed(() =>
    Boolean(
      user.value?.raw_permissions?.find(
        permission => permission === 'user_support_pos' || permission === 'user_support_mortgage'
      )
    )
  )

  const numberOfUnreadMessages = computed(() => {
    return threads.value?.reduce((acc, thread) => {
      if (!thread.is_read) {
        if (isChatSupport.value && thread.role_user_msg === 'user') {
          acc += 1
        } else if (!isChatSupport.value && thread.role_user_msg === 'admin') {
          acc += 1
        }
      }

      return acc
    }, 0)
  })

  /**
   * Индикатор того, что есть не прочитанное сообщение
   */
  const haveUnreadMessage = computed(() => Boolean(numberOfUnreadMessages.value))

  // Если юзера изменился  обязательно нужно
  watch(user, () => reset())

  watch(threadId, () => {
    // При изменении треда нужно обнулять эти значения
    messages.value = null
    messagesTotalSize.value = null
  })

  watch(
    () => websocketStore.message,
    message => {
      const { _data, _type } = message

      if (_type === 'chatmessage_create') {
        onMessageAdd(_data)
      }

      if (_type === 'chatmessage_delete') {
        onMessageDelete(_data.id)
      }

      if (_type === 'chatmessage_read') {
        onMessageRead(_data)
      }
    }
  )

  watch(
    salespoint,
    salespoint => {
      if (salespoint && !threadId.value) {
        threadId.value = salespoint.id
      }
    },
    { immediate: true }
  )

  function reset() {
    threadId.value = null
    messages.value = null
    messagesTotalSize.value = null
    updateThreads()
  }

  /**
   * Получить список тредов
   */
  async function getThreads() {
    const res = await _getThreads.post()

    if (res?._code === 0 && res._data) {
      threads.value = res._data
    }

    return res
  }

  // Апи метод
  const _getMessages = useGetChatmessageList()
  /**
   * Получить сообщения
   */
  async function getMessages() {
    if (
      // Если выбран тред
      threadId.value &&
      // Если общие кол-во сообщений больше чем текущий индекс последнего сообщения
      (!messagesTotalSize.value || messagesTotalSize.value > messagesStartIndex.value)
    ) {
      const res = await _getMessages.post({
        body: { salespoint_id: threadId.value },
        search: { _page_size: messagesPageSize, _start_index: messagesStartIndex.value }
      })

      if (res?._code === 0 && res._data) {
        if (!messages.value) {
          messages.value = []
        }

        messages.value?.push(...res._data)
        messagesTotalSize.value = res._data_total_size || 0
      }

      return res
    }
  }

  // Апи метод
  const _readMessage = useReadChatmessage()
  /**
   * Изменить статус сообщения на "прочитано"
   */
  async function readMessage(id: number) {
    const res = await _readMessage.post({ body: { ids: [id] } })

    if (res?._code === 0 && res._data && messages.value) {
      const readMessage = res._data[0]
      onMessageRead(readMessage)
    }

    return res
  }

  // Апи метод
  const _deleteMessage = useDeleteChatmessage()
  /**
   * Удаление сообщения
   */
  async function deleteMessage(id: number) {
    const res = await _deleteMessage.post({ body: { id } })

    if (res?._code === 0) {
      onMessageDelete(id)
    }

    return res
  }

  function sortThreads(threads: ChatmessageThread[]) {
    return threads?.sort((a, b) => {
      // Не прочитанные сообщения выше
      if (!a.is_read && a.role_user_msg === 'user') {
        return -1
      }

      // Не прочитанные сообщения выше
      if (!b.is_read && b.role_user_msg === 'user') {
        return 1
      }

      // Если сообщения прочитаны, то выбираем по времени
      if (new Date(a.latest_created_at).getTime() > new Date(b.latest_created_at).getTime()) {
        return -1
      }

      if (new Date(b.latest_created_at).getTime() > new Date(a.latest_created_at).getTime()) {
        return 1
      }

      return 0
    })
  }

  // Апи метод
  const _updateMessage = useUpdateChatmessage()
  /**
   * Создание сообщений
   */
  async function updateMessage(data: MessageUpdateWithSalespointIds | MessageUpdateWithGroupId) {
    const res = await _updateMessage.post({
      body: data
    })

    if (res?._code === 0 && res._data) {
      res._data.forEach(message => {
        onMessageAdd(message)
      })
    }

    return res
  }

  /**
   * Обновить список тредов
   */
  function updateThreads() {
    // Если предыдущий запрос еще не обработался, то новый не начинаем
    // if (!_getThreads.pending.value) {
    // Вызываем обновление списка тредов
    // updateThreadWithMessage(messages)
    // }
  }

  function updateThreadWithMessage(message: Chatmessage) {
    if (!threads.value) return

    const threadIndex = threads.value?.findIndex(thread => thread.id === message.salespoint_id)
    if (typeof threadIndex === 'number' && threadIndex >= 0) {
      const thread = threads.value[threadIndex]
      if (thread) {
        const [last_name, first_name, middle_name] = message.username.split(' ')

        const updatedThread: ChatmessageThread = {
          id: thread.id,
          title: thread.title,
          quantity: thread.quantity,
          latest_id: message.id,
          latest_created_at: message.created_at,
          latest_first_name: first_name,
          latest_last_name: last_name,
          latest_middle_name: middle_name,
          latest_short_message: message.message,
          file: message.file,
          is_read: message.is_read,
          role_user_msg: message.role_user_msg
        }

        threads.value[threadIndex] = updatedThread
      }
    }
  }

  /**
   * При добавлении сообщения
   */
  function onMessageAdd(addedMessage: Chatmessage) {
    if (
      threadId.value &&
      // Если сообщение от того же треда
      addedMessage.salespoint_id === threadId.value &&
      // Если список сообщений есть
      messages.value
    ) {
      // Проверяем, если такое сообщение в списке
      const index = messages.value.findIndex(message => message.id === addedMessage.id)
      if (index === -1) {
        // Сообщения в списке нет и поэтому мы добавляем его
        messages.value?.unshift(addedMessage)
      }
    }

    addToast(addedMessage)
    updateThreadWithMessage(addedMessage)
    // Актуализируем список тредов
    // updateThreads()
  }

  function addToast(message: Chatmessage) {
    // Если пользователь находиться не на странице с сообщениями
    if (router.currentRoute.value.name !== ChatmessageRouteNames.threads) {
      // Если сообщение от админа и пользователь тоже админ, то не вызываем уведомление
      if (isChatSupport.value && message.role_user_msg === 'admin') {
        return
      }

      // Если сообщение от юзера и пользователь тоже является юзером, то не вызываем уведомление
      if (!isChatSupport.value && message.role_user_msg === 'user') {
        return
      }

      // Если сообщение от самого пользователя
      if (message.user_id === user.value?.id) {
        return
      }

      // Отправляем уведомление, что появилось сообщение
      toast.add({
        severity: 'secondary',
        summary: getUserName(message),
        detail: getShortMessage(message)
      })
    }
  }

  /**
   * При удалении сообщения
   */
  function onMessageDelete(messageId: number) {
    if (messages.value) {
      // Ищем индекс сообщения в списке
      const index = messages.value.findIndex(message => message.id == messageId)

      if (typeof index === 'number' && index >= 0) {
        // Сообщение было найдено и надо его убрать из списка
        messages.value?.splice(index, 1)
      }
    }

    // Актуализируем список тредов
    // updateThreads()
    getThreads()
  }

  /**
   * При прочтении сообщения
   */
  function onMessageRead(readMessage: Chatmessage) {
    if (messages.value) {
      // Ищем индекс сообщения в списке
      const index = messages.value.findIndex(message => message.id === readMessage.id)

      if (typeof index === 'number' && index >= 0) {
        // Сообщение было найдено и его необходимо заменить на актуальный
        messages.value[index] = readMessage
      }
    }

    // Актуализируем список тредов
    // updateThreads()
    getThreads()
  }

  return {
    threads,
    thread,
    threadId,
    messages,
    isChatSupport,
    messagesPageNumber,
    updateThreads,
    numberOfUnreadMessages,
    haveUnreadMessage,
    getThreads,

    readMessage: {
      ..._readMessage,
      post: readMessage
    },

    getMessages: {
      ..._getMessages,
      post: getMessages
    },

    updateMessage: {
      ..._updateMessage,
      post: updateMessage
    },

    deleteMessage: {
      ..._deleteMessage,
      post: deleteMessage
    }
  }
})
