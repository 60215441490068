<template>
  <div class="flex flex-col">
    <div class="p-2 border border-solid border-gray-300 rounded-t-md">{{ title }}</div>
    <div class="p-2 border border-solid border-gray-300 border-t-0 border-b-0">
      <InputText
        v-model="search"
        size="small"
        class="w-full"
        placeholder="поиск"
      />
    </div>

    <div
      ref="itemsContainer"
      class="max-h-[350px] min-h-[350px] border-t-0 border border-solid border-gray-300 rounded-t-none rounded-md overflow-auto"
      @scroll="handleScroll"
    >
      <div
        v-for="item in items"
        :key="item.code"
        class="text-gray-900 p-2 cursor-pointer hover:bg-gray-200 transition-colors"
        @click="emits('select', item)"
      >
        {{ getItemLabel(item) }}
      </div>

      <div
        v-if="loading"
        class="text-gray-500 p-2"
      >
        Загрузка...
      </div>

      <div
        v-if="showMore"
        class="text-gray-700 p-2 cursor-pointer hover:bg-gray-200 transition-colors"
        @click="emits('showMore')"
      >
        Показать еще...
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputText from 'primevue/inputtext'
import { ref, watch } from 'vue'

type GetFn = (item: any) => any

const props = withDefaults(
  defineProps<{
    title?: string
    items: any[] | null
    value?: string | GetFn
    label?: string | GetFn
    loading?: boolean
    showMore?: boolean
  }>(),
  {
    label: 'label',
    value: 'code'
  }
)

const emits = defineEmits<{
  select: [any]
  scrollDown: []
  search: [string | null]
  showMore: []
}>()

/**
 * Значение строки поиска
 */
const search = ref<string | null>(null)

/**
 * Ссылка на html элемент, где находятся все items
 */
const itemsContainer = ref<Element | null>(null)

/**
 * Предыдущие значение расстояние прокрутки, если считать сверху
 */
const previousScrollTop = ref(0)

// Следим за строкой поиска и выдаем emit при его изменении
watch(search, searchValue => emits('search', searchValue))

/**
 * Обработчик прокрутки
 */
function handleScroll() {
  if (itemsContainer.value) {
    // Полная высота прокрутки
    const scrollHeight = itemsContainer.value.scrollHeight
    // Видимая высота
    const clientHeigh = itemsContainer.value.clientHeight
    // Текущие расстояние прокрутки
    const currentScrollTop = itemsContainer.value.scrollTop
    // Индикатор, что прокручивают вниз
    const isScrollingDown = currentScrollTop > previousScrollTop.value

    if (
      // Если прокручивают в низ
      isScrollingDown &&
      // Если текущая прокрутка ниже или равна 200 px от конца прокрутки
      currentScrollTop + clientHeigh >= scrollHeight - 25
    ) {
      emits('scrollDown')
    }

    // Сохраняем текущие расстояние прокрутки
    previousScrollTop.value = currentScrollTop
  }
}

/**
 * Получение название для item
 */
function getItemLabel(item: any) {
  if (typeof props.label === 'function') {
    return props.label(item)
  }

  return item[props.label]
}
</script>
