<template>
  <div class="bg-gray-100">
    <div
      v-if="checkLoginLoading"
      class="min-h-screen flex items-center justify-center"
    >
      <div class="flex gap-4 justify-start items-center">
        <img
          src="@/assets/inpocket-full-logo.svg"
          width="250"
        />
        <ProgressSpinner
          style="width: 40px; height: 40px"
          strokeWidth="6"
        />
      </div>
    </div>

    <div v-else>
      <Toast />
      <DynamicDialog />

      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import DynamicDialog from 'primevue/dynamicdialog'
import Toast from 'primevue/toast'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import ProgressSpinner from 'primevue/progressspinner'
import { useMortgageStore } from '@/entities/mortgage'
import { useMortgageTablePageStore } from '@/pages/mortgage/MortgageTablePage/mortgage-table-page-store'
import { useAuthModel } from '@/features/auth'
import { useSalespointStore } from '@/store'
import { setMode } from 'simpleloan_api'
import { useSelectSalespointModel } from '@/features/salespoint'
import { accountRouteNames } from './router/routes'

const router = useRouter()

const authModel = useAuthModel()

const salespointStore = useSalespointStore()

const checkLoginLoading = ref<boolean>(true)

const { openModal } = useSelectSalespointModel()

if (process.env.NODE_ENV === 'development') {
  setMode('development')
} else {
  setMode('production')
}

useMortgageStore()

useMortgageTablePageStore()

authModel.checkLogin.post().then(async res => {
  if (res?._code === 0) {
    if (!salespointStore.salespoint) {
      setTimeout(() => openModal())
    }

    checkLoginLoading.value = false
  } else if (res?._code !== 0) {
    router.push({ name: accountRouteNames.login }).then(() => {
      checkLoginLoading.value = false
    })
  }
})
</script>
