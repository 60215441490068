import { useSalespointModel } from '@/entities/salespoint'
import { useUserModel } from '@/entities/user'
import { myAsyncComputed } from '@/shared'
import { defineStore } from 'pinia'
import {
  Dict,
  Mortgage,
  MortgageDictNames,
  MortgageUpdate,
  useCloneMortgage,
  useGetMortgage,
  useGetMortgageDict,
  useGetMortgageFieldRequired,
  useGetMortgageProgram,
  useGetSalespoint,
  useGetUser,
  useUpdateMortgage
} from 'simpleloan_api'
import { computed, reactive, ref } from 'vue'

export * from './mortgage-store'
export * from './info'
export * from './mortgage-model'
export * from './mortgage-table'

export * from './mortgage-customer'

export const useMortgageSecondModel = defineStore('mortgage-model-second', () => {
  const userModel = useUserModel()
  const salespointModel = useSalespointModel()

  /**
   * Ипотечная заявка
   */
  const app = ref<Mortgage | null>(null)

  /**
   * Список ипотечных заявок
   */
  const appList = ref<Mortgage[] | null>(null)

  /**
   * Справочники
   */
  const dictState = reactive<{ [key in MortgageDictNames]?: Dict }>({})
  const dicts = new Proxy(dictState, {
    get(target, prop: MortgageDictNames) {
      if (typeof prop === 'symbol' || prop.includes('__')) {
        return target[prop]
      }

      if (!target[prop]) {
        useGetMortgageDict(prop)
          .post()
          .then(res => {
            dictState[prop] = res?._data
          })
      }

      return target[prop]
    }
  })

  /**
   * Юзер привязанный к заявке
   */
  const user = myAsyncComputed(
    null,
    async () => {
      if (userId.value) {
        const res = await useGetUser().post({ body: { id: userId.value } })
        return res?._data || null
      }

      return null
    },
    { lazy: true }
  )

  // id пользователя, который привязан к заявке.
  // Использую как отдельную переменную, что бы asyncComputed (user) не пересчитывался каждый раз при изменении заявки
  const userId = computed(() => (app.value ? app.value.user_id : userModel.user?.id))

  /**
   * Торговая точка привязанная к заявке
   */
  const salespoint = myAsyncComputed(
    null,
    async () => {
      if (salespointId.value) {
        const res = await useGetSalespoint().post({ body: { id: salespointId.value } })
        return res?._data || null
      }

      return null
    },
    { lazy: true }
  )

  // id торговой точки, которая привязана к заявке.
  // Использую как отдельную переменную, что бы asyncComputed (salespoint) не пересчитывался каждый раз при изменении заявки
  const salespointId = computed(() => (app.value ? app.value.salespoint_id : salespointModel.salespoint?.id))

  /**
   * Ипотечная программа
   */
  const program = myAsyncComputed(
    null,
    async () => {
      if (programId.value) {
        const { post } = useGetMortgageProgram()
        const res = await post({ body: { id: programId.value } })
        return res?._data || null
      }

      return null
    },
    { lazy: true }
  )

  // id ипотечной программы, которая привязана к заявке.
  // Использую как отдельную переменную, что бы asyncComputed (program) не пересчитывался каждый раз при изменении заявки
  const programId = computed(() => app.value?.program_id)

  /**
   *  Название выбранного банка
   */
  const bankName = computed(() => app.value?.bank_app_status?.bank || program.value?.bank || null)

  /**
   *  Список обязательных полей
   */
  const fieldRequired = myAsyncComputed(
    null,
    async () => {
      if (bankName.value) {
        const res = await useGetMortgageFieldRequired().post({
          body: { bank: bankName.value }
        })

        return res?._data || null
      }

      return null
    },
    { lazy: true }
  )

  const { post: getPost, pending: getPending } = useGetMortgage()
  const { post: updatePost, pending: updatePending } = useUpdateMortgage()
  const { post: clonePost, pending: clonePending } = useCloneMortgage()

  
  async function get(id: number) {
    const res = await getPost({ body: { id } })

    if (res?._code === 0 && res._data) {
      app.value = res._data
    }

    return res
  }

  // Обновление/создание заявки
  async function update(value: MortgageUpdate) {
    const res = await updatePost({ body: value })

    if (res?._code === 0 && res._data) {
      app.value = res._data
    }

    return res
  }

  // Клонирование заявки
  async function clone(id: number) {
    const res = await clonePost({ body: { id } })
    if (res?._code === 0 && res._data) {
      // Если заявки еще нет в списке
      if (!appList.value?.find(app => app.id === res._data?.id)) {
        appList.value?.push(res._data)
      }
    }

    return res
  }

  return {
    app,
    appList,
    dicts,
    user,
    salespoint,
    program,
    bankName,

    get,
    getPending,

    update,
    updatePending,

    clone,
    clonePending
  }
})
