import { RouteRecordRaw } from 'vue-router'

export const ChatmessageRouteNames = {
  threads: 'Chatmessage Threads',
  globalMessage: 'Chatmessage Global Message',
}

export const chatmessageRoutes: RouteRecordRaw = {
  path: '/chatmessage',
  meta: { header: true },
  // beforeEnter: [authGuard],
  children: [
    {
      path: '',
      name: ChatmessageRouteNames.threads,
      component: () => import('@/pages/ChatMessagePage')
    },
    {
      path: 'global-message',
      name:  ChatmessageRouteNames.globalMessage,
      component: () => import('@/pages/ChatmessageGlobalMessagePage')
    }
  ]
}
